$mine-shaft: rgb(47, 47, 47); // Mine Shaft
$dove-gray: rgb(70, 70, 70); // Dove Gray
$gray: rgb(136, 136, 136); // Gray
$silver-chalice: rgb(181, 181, 181); // Silver Chalice
$mercury: rgb(229, 229, 229); // Mercury
$white: #fff;

$success: rgb(100, 188, 38); // Success
$error: rgb(234, 22, 1); // Error
$alert: rgb(250, 210, 2); // Alert

$fun-blue-1: #161513; // Fun Blue-1
$curriki-main-blue: #161513; // Curriki Main Blue
$dodger-blue: rgb(46, 141, 255); // Dodger Blue
$zircon: rgb(229, 238, 255); // Zircon

$raw-umber: rgb(127, 86, 19); // Raw Umber
$curriki-main-yellow: rgb(255, 177, 36); // Curriki Main Yellow
$my-sin: rgb(255, 208, 134); // My Sin
$papaya-whip: rgb(255, 239, 214); // Papaya Whip

$fruit-salad: rgb(77, 135, 85); // Fruit Salad
$fern: rgb(111, 191, 119); // Fern
$madang: rgb(143, 242, 151); // Madang
$viridian: rgb(69, 137, 112); // Viridian
$silver-tree: rgb(97, 188, 152); // Silver Tree
$magic-mint: rgb(126, 242, 193); // Magic Mint

$coral-red: rgb(255, 64, 59); // Coral Red
$cornflower-lilac: rgb(255, 159, 156); // Cornflower Lilac
$pippin: rgb(255, 217, 216); // Pippin

$crusta: rgb(255, 124, 56); // Crusta
$romantic: rgb(255, 191, 157); // Romantic
$tuft-bush: rgb(255, 229, 215); // Tuft Bush

$electric-violet: rgb(155, 93, 251); // Electric Violet
$blue-chalk: rgb(204, 173, 253); // Blue Chalk
$blue-chalk-2: rgb(235, 222, 254); // Blue Chalk-2
$heliotrope: rgb(212, 108, 251); // Heliotrope
$mauve: rgb(233, 181, 253); // Mauve
$white-pointer-1: rgb(246, 226, 254); // White Pointer-1
$blush-pink: rgb(255, 120, 251); // Blush Pink
$blush-pink-1: rgb(255, 187, 253); // Blush Pink-1
$pink-lace-1: rgb(255, 228, 254); // Pink Lace-1
