@import 'assets/css/index';

.top-header {
  .top-info svg {
    color: #fff !important;
    font-size: 28px;
  }
  background-color: #3a3632 !important;

  .dropdown-toggle {
    background: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;

    &:after {
      border-top-color: gray;
    }
  }

  .group-search-logo {
    display: flex;
    align-items: center;

    .tophd_left {
      .top_logo {
        width: 300px;
        height: 80px;
        margin-top: 17px;
        .nav-logo {
          width: 132px;
          height: 100%;
        }
      }
    }
  }

  .notification-bar {
    .notification-alert {
      position: relative;

      .alert-added {
        width: 10px;
        height: 10px;
        background-color: #d54646;
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .notification-status {
      font: normal normal 600 14px/20px $rubik;
      letter-spacing: 0;
      color: #8f8fa1;
      padding: 15px 0 5px 25px;
    }

    .user-dropdown {
      // width: 480px !important;
      // box-shadow: 0 0 99px #00000021;
      // border-radius: 3px;

      width: 470px !important;
      height: 553px;
      left: calc(50% - 281px / 2 + 2.5px);
      top: calc(50% - 290px / 2 - 4px);
      background: #ffffff;
      box-shadow: 1px 20px 50px 1px rgba(0, 0, 0, 0.15);
      border-radius: 3px;
      overflow: hidden;

      .scroll-notification {
        max-height: 480px;
        overflow-y: auto;

        .header-data {
          display: flex;
          justify-content: space-between;
          padding: 15px 25px;
          border-bottom: 1px solid #f2f0f0;

          h2 {
            font: normal normal 600 18px/20px $rubik;
            letter-spacing: 0;
            color: #0f0f0f;
            margin-bottom: 0;
          }

          h3 {
            font: normal normal 600 14px/20px $rubik;
            letter-spacing: 0;
            color: #5250c1;
            margin-bottom: 0;
          }
        }

        .dropdown-item {
          padding: 0;

          &:nth-of-type(odd) {
            .user-detail .user-icons {
              background: #c3c0e7 0% 0% no-repeat padding-box;
              color: #6d629a;
            }
          }
        }

        p {
          white-space: pre-wrap;
        }
      }

      .btn-all-notification {
        border-top: 1px solid #f2f0f0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 10px 0;

        .all-notification {
          text-decoration: underline;
          font: normal normal 600 14px/20px $rubik;
          color: #5250c1;
          width: 100%;
        }

        .notification-setting {
          font: normal normal 600 14px/20px $rubik;
          letter-spacing: 0;
          color: #5250c1;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
    }

    h2.title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;

      color: #515151;
    }
  }

  .navbar-link li .dropdown-menu {
    width: 200px;
    padding: 20px;
    margin-top: -5.5px !important;
    h2.title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;

      color: #515151;
    }
  }
  .navbar-link {
    button {
      padding: 0px !important;
    }
  }
  .active {
    .dropdown-menu {
      display: block;
    }
  }

  .hide {
    .dropdown-menu {
      display: none;
    }
  }
}

.search-block {
  position: relative;
  overflow: hidden;
  width: 380px;
  height: 40px;
  margin-right: 0px;

  .searchicon {
    position: absolute;
    top: 11px;
    right: 40px;
    width: 18px;
    cursor: pointer;
  }

  .fa-chevron-down {
    position: absolute;
    right: 20px;
    top: 13px;
    color: $fun-blue-1;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
  }
}

.search-div {
  align-items: center;
  margin-right: 12px;

  #dropdown-basic {
    background-color: transparent;
    position: initial;
    right: 4px;
    color: $fun-blue-1;
    top: 0px;
    padding: 0;
  }

  .search-block + .dropdown-menu {
    box-shadow: 0 0 20px #00000029;
    border: 1px solid #cfcfcf;
    opacity: 1;
    padding: 30px;
    background: #fff;
    margin-top: 0;
    width: 600px;
    position: absolute;
    right: auto !important;
    left: initial !important;
    border-radius: 5px;
    overflow-y: auto;
    max-height: 615px;
    top: 20px !important;
  }

  .overlay-search-form {
    .form-group {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;

      input,
      select {
        border: none;
        width: 100%;
        text-align: left;
        font-size: 14px;
        line-height: 26px;
        border-bottom: 1px solid #cfcfcf;
        letter-spacing: 0;
        color: #636363;
        opacity: 1;
        padding-bottom: 5px;
        background-color: #ffffff;
      }

      .error {
        color: red;
        display: block;
      }

      .keywords-de {
        background-color: $curriki-main-blue;
        color: #fff;
        border-radius: 30px;
        padding: 6px 10px;
        font-size: 12px;
        position: relative;
        margin: 5px 5px 0 0;

        .iocns {
          color: #fff;
          background-color: #ff0000d1;
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          border-radius: 30px;
          stroke-width: 1px;
          cursor: pointer;
          display: none;

          svg {
            margin: 10px auto;
            display: flex;
          }
        }

        &:hover .iocns {
          display: block;
        }
      }

      input[type='date'],
      input[type='select'] {
        cursor: pointer;
      }

      .radio-btns {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: -10px 0px;

        label {
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: 14px;
          line-height: normal;
          color: #636363;
          font-weight: 400;
          cursor: pointer;

          input {
            width: auto;
            margin-right: 10px;
            cursor: pointer;
          }
          input[type='radio']:after {
            width: 15px;
            height: 15px;
            border-radius: 15px;
            top: -2px;
            left: -1px;
            position: relative;
            background-color: #fff;
            content: '';
            display: inline-block;
            visibility: visible;
            border: 2px solid $fun-blue-1;
          }
          input[type='radio']:checked:after {
            width: 15px;
            height: 15px;
            border-radius: 15px;
            top: -2px;
            left: -1px;
            position: relative;
            background-color: $fun-blue-1;
            content: '';
            display: inline-block;
            visibility: visible;
            border: 2px solid $fun-blue-1;
          }
        }
      }
    }
  }

  .dual select,
  .dual input {
    width: 48% !important;
  }

  h4 {
    text-align: left;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;

    letter-spacing: 0;
    color: $curriki-main-blue;
    text-transform: capitalize;
    opacity: 1;
    padding-bottom: 20px;
  }

  .form-group {
    display: flex;
    justify-content: space-between;
  }

  .form-group.wrap-keyword {
    display: flex;
    justify-content: initial;
    flex-wrap: wrap;
  }

  button {
    font-size: 15px;
    line-height: 20px;
    background: $curriki-main-blue 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
    border: none;
    letter-spacing: 0;
    color: #ffffff;
    //width: 102px;
    height: auto;
    text-align: center;
    cursor: pointer;
  }

  .dual_activity {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }
}

.tophd_left {
  width: auto;
  height: 75px;
  display: flex;
  align-items: center;

  img {
    width: auto;
    height: 75px;
  }
}

.search-term {
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid $fun-blue-1;
  box-sizing: border-box;
  border-radius: 20px;
  opacity: 1;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0;
  color: $fun-blue-1;
  padding-left: 16px;
  padding-right: 60px;
  width: 100%;
  height: 100%;
  font-family: $sans;

  &::placeholder {
    color: $fun-blue-1;
  }
}

.header-icon-text {
  font-size: 14px;
  text-align: center;
  color: #fff;
  margin-bottom: 0px;
  display: block;
  font-family: $sans;
  padding-top: 3px;
  line-height: 20px;
}

.search-submit {
  position: absolute;
  right: -10px;
  z-index: 99;
  top: 3px;
  border: 0;
  background: transparent;
  font-size: 18px;
  color: #fff;
  width: 27px;
  height: 12px;
  border-radius: 0;
  margin-right: 20px;
}

.dropdown-multitenancy {
  div.text {
    // text-overflow: ellipsis;
    // overflow: hidden;
    // text-align: center;
    // width: 60px;
  }
  .dropdown-toggle {
    color: $fun-blue-1 !important;
    line-height: 0;
    .text {
      font-size: 14px;
      font-family: $sans;
      padding-top: 3px;
      line-height: 20px;
      color: #fff;
    }

    &:hover,
    &:active,
    &:focus {
      color: $fun-blue-1;
    }
  }

  .dropdown-menu {
    min-width: 398px;
    max-height: 359px;
    background: #ffffff;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    border: none;
    padding: 10px 25px;
    overflow: auto;
  }

  a {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: $fun-blue-1;
    padding: 0;
  }

  .all-tg-lister {
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 0px;
  }

  p {
    font-weight: normal;
    font-size: 12.8px;
    line-height: 127.85%;
    color: #888888;
    margin-bottom: 0px;
  }
}

.profile-avatar {
  height: 24px;
  background: transparent;
  color: white;
  border-radius: 50%;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: -8px; */
  font-size: 15px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  font-weight: 400;
  width: 24px;
  margin: 0 auto;
}
.user-dropdown-item {
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  color: $fun-blue-1;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;

  img {
    width: 20px;
    height: 20px;
    margin: 0 10px 0 -15px;
    // border: 5px solid black;
  }
  &:active {
    color: white;
  }
}

hr {
  width: 218.5px;
  height: 5px;
  left: calc(50% - 218.5px / 2 - 3.75px);
  top: calc(50% - 0px / 2 + 12px);
  border: 1px solid #e5e5e5;
}

.user-dropdown-item-name {
  display: flex;
  flex-direction: row;

  .basic-info {
    margin-left: 10px;
  }
  .name {
    // height: 10px;
    padding: 0;
    margin: 0;
    font-size: 16px;
    // border: 1px solid black;
  }
  .email {
    padding: 0;
    margin: 0;
    font-size: 12px;
    color: #161513;
    // border: 1px solid black;
  }
}
