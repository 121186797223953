@import 'index';
@import 'react-toastify/dist/ReactToastify.css';
@import '~bootstrap/scss/bootstrap';

body {
  margin: 0;
  background-color: #eee;
  font-size: 14px;
  color: #777;
  font-family: $rubik !important;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*:focus {
  outline: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d6d6d6;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  cursor: pointer;
}

.clearfix {
  clear: both;
}

.thumb-image {
  object-fit: none;
  width: 100%;
  max-height: 200px;
}

.student-score {
  text-decoration: none;
  color: gray;
}

.btn-primary {
  border: none;
}

.playlist-dropdown {
  .playlist-dropdown-btn {
    height: 24px;
    padding: 0 10px !important;
    color: #868686 !important;
    background: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;

    &:after {
      display: none;
      border-top-color: white !important;
    }
  }

  .alert {
    margin-top: 1rem;
  }
}

.slick-slider {
  .alert-info {
    width: 300px;
    white-space: nowrap;
  }
}

.welcome-video {
  width: 60%;
}

.alert-link-ref {
  color: #155724;
  text-decoration: underline;

  &:hover {
    color: #155724;
  }
}

.content-wrapper.list-grid {
  .playlist-resource {
    width: 100%;

    .col-md-3.check {
      max-width: 100%;

      .program-tile {
        min-height: 100%;
        display: flex;
        flex-direction: row;
      }

      .program-content {
        width: 100%;
      }

      .program-thumb {
        width: 260px;
        height: 140px;
      }

      .button-bottom {
        justify-content: flex-end;

        a {
          width: 80px;
          display: flex;
          border: 0;
        }
      }
    }
  }
}

.content-wrapper.normal-grid {
  .check-home {
    margin-top: 10px;
    display: flex;
    flex-wrap: nowrap;
  }

  .flex-smaple {
    display: flex;
    flex-wrap: wrap;
  }

  .drag-class {
    display: inline-flex;
  }

  .playlist-resource {
    max-width: 25%;
    width: 24%;
    margin-right: 17px;
    padding: 0;

    &:last-child {
      margin-right: 0;
    }

    .col-md-3.check {
      max-width: 100%;
      margin-bottom: 17px;
      padding: 0;
    }

    .program-tile {
      position: relative;
    }

    .program-tile {
      position: relative;
    }

    .button-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      margin: 0;

      a {
        width: 33.3%;
        flex: 1;
      }
    }
  }
}

.content-wrapper.small-grid {
  .check-home {
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
  }

  .flex-smaple {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
  }

  .playlist-resource {
    max-width: 20%;
    width: 20%;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }

    .col-md-3.check {
      max-width: 100%;
      margin-bottom: 12px;
      padding: 0;
    }

    .program-thumb {
      height: 150px;
    }

    .program-tile {
      position: relative;
      min-height: 380px;
      height: 300px;
    }

    .button-bottom {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      margin: 0;

      a {
        width: 33.3%;
        flex: 1;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: $fun-blue-1;
        align-items: center;
      }

      .dropdown-menu a {
        width: 100%;
      }
    }
  }
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.5) !important;
  z-index: 1050;
}

.react-confirm-alert {
  min-width: 650px;
  background: #fff !important;
  padding: 25px;
  border-radius: 5px;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.curriki-btn-extra {
  border: 0;
  border-radius: 0.25em;
  color: #fff;
  font-size: 1.0625em;
  display: inline-block;
  border-left-color: rgb(48, 133, 214);
  border-right-color: rgb(48, 133, 214);
  margin: 0.3125em;
  padding: 5px 30px;
  box-shadow: none;
  font-weight: 500;
  cursor: pointer;
  background: #161513;
  box-shadow: 0px 2px 6px 1px rgba(8, 72, 146, 0.16);
  border-radius: 18px;
}

.dropdown-menu.check.overflow-enhancment {
  left: -20px !important;
  top: 22px !important;
  a {
    word-break: break-all !important;
  }
}

.saveclosemodel {
  width: 150px;
  font-size: 16px;
  color: #ffffff;
  background: #161513;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  height: 40px;
  cursor: pointer;
}

.backclosemodel {
  width: 150px;
  font-size: 16px;
  color: #333;
  background: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  height: 40px;
  cursor: pointer;
}

.add-role-form {
  select {
    width: 80px !important;
    height: 28px !important;
    background: #e5eeff;
    border-radius: 4px;
    font-family: 'rubic';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #161513;
    border: none !important;
  }
}
